import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'
import Edit from './edit'

const mapPropsToPanel = (props, context) => ({
  title: 't(Senders)',
  collection: {
    endpoint: '/api/admin/truevail/admin/senders',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Sender)', key: 'rfc822' },
      { label: 't(Sending Rate)', key: 'sending_rate', collapsing: true, align: 'right' },
      { label: 't(Status)', key: 'email_status', primary: true, collapsing: true, format: (sender) => (
        <StatusToken status={ sender.email_status } />
      ) }
    ],
    criteria: {
      fields: [
        { label: 't(Sender)', fields: [
          { name: 't(Sender Type)', key: 'type', type: 'select', options: ['hosted','custom'] },
          { name: 't(Status)', key: 'email_status', type: 'select', options: ['not sent','pending','expired','verified'] }
        ] }
      ]
    },
    defaultSort: { key: 'name', order: 'asc' },
    empty: {
      title: 't(No Senders)',
      text: 't(There are no senders for your advisors)',
      icon: 'paper-plane-o'
    },
    entity: 'sender',
    recordTasks: (record) => [
      {
        label: 't(Update Sending Rate)',
        svg: 'percent',
        modal: <Edit sender={ record } />
      }
    ],
    onClick: (record) => {
      const { provider, host } = context
      host.window.open(`${provider.admin_host}/${record.team.subdomain}/team/senders/${record.id}`)
    }
  }
})

export default Panel(null, mapPropsToPanel)
