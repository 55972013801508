import Image from '@admin/components/image'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'

const FolderToken = ({ folder }) => (
  <div className="campaign-token folder-token">
    <div className="campaign-token-preview">
      { folder.thumbnail ?
        <Image src={ folder.thumbnail.path } /> :
        <Icon svg="folder" />      
      }
    </div>
    <div className="campaign-token-label">
      <div className="token-link">
        { folder.title }
      </div>
    </div>
  </div>
)

FolderToken.propTypes = {
  folder: PropTypes.object
}

export default FolderToken
