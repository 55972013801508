const calendar = [
  { 
    code: 'email_campaigns_scheduled',
    entity: 'email campaign',
    action: 'scheduled',
    query: `
    select campaigns_email_campaigns.id, 
    campaigns_email_campaigns.team_id, 
    'campaigns:email_campaigns_scheduled' as code,
    date(campaigns_email_campaigns.send_at) as date
    from campaigns_email_campaigns
    where campaigns_email_campaigns.sent_at is null
    and campaigns_email_campaigns.send_at is not null
    and campaigns_email_campaigns.deleted_at is null
    `
  },
  { 
    code: 'email_campaigns_sent',
    entity: 'email campaign',
    action: 'sent',
    query:  `
    select campaigns_email_campaigns.id, 
    campaigns_email_campaigns.team_id, 
    'campaigns:email_campaigns_sent' as code,
    date(campaigns_email_campaigns.sent_at) as date
    from campaigns_email_campaigns
    where campaigns_email_campaigns.sent_at is not null
    and campaigns_email_campaigns.deleted_at is null
    `
  },
  {
    code: 'social_campaigns_scheduled',
    entity: 'social campaign',
    action: 'scheduled',
    query: `
    select campaigns_social_campaigns.id, 
    campaigns_social_campaigns.team_id, 
    'campaigns:social_campaigns_scheduled' as code,
    date(campaigns_social_campaigns.post_at) as date
    from campaigns_social_campaigns
    where campaigns_social_campaigns.posted_at is null
    and campaigns_social_campaigns.post_at is not null
    and campaigns_social_campaigns.deleted_at is null
    `
  },
  { 
    code: 'social_campaigns_posted',
    entity: 'social campaign',
    action: 'posted',
    query:  `
    select campaigns_social_campaigns.id, 
    campaigns_social_campaigns.team_id, 
    'campaigns:social_campaigns_posted' as code,
    date(campaigns_social_campaigns.posted_at) as date
    from campaigns_social_campaigns
    where campaigns_social_campaigns.posted_at is not null
    and campaigns_social_campaigns.deleted_at is null
    `
  }
]

export default calendar
