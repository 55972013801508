import FoldersUpdateBatch from '@apps/truevail/admin/components/folders_update_batch'
import TagsUpdateBatch from '@apps/truevail/admin/components/tags_update_batch'
import LibraryAddBatch from '@apps/truevail/admin/components/library_add_batch'
import LibraryAdd from '@apps/truevail/admin/components/library_add'
import LibraryToken from '@apps/truevail/admin/tokens/library'
import DesignToken from '@apps/truevail/admin/tokens/design'
import DesignCard from '@apps/truevail/admin/cards/design'
import Panel from '@admin/components/panel'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Designs)',
  collection: {
    endpoint: '/api/admin/truevail/admin/designs',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (design) => (
        <DesignToken design={ design } />
      ) },
      { label: 't(Library)', key: 'library_status', sort: 'status', collapsing: true, align: 'center', format: LibraryToken }
    ],
    card: (design) => (
      <DesignCard design={ design } />
    ),
    defaultSort: { key: 'title', order: 'asc' },
    empty: {
      svg: 'palette',
      title: 't(No Designs)',
      text: 't(You have not yet created any designs)',
      buttons: [
        { 
          label: 't(Create New Design)', 
          modal: New 
        }
      ]
    },
    entity: 'design',
    selectable: true,
    batch: {
      batch_type: 'truevail_designs',
      tasks: [
        { 
          label: 't(Add to Library)', 
          svg: 'book_plus',
          action: 'publish', 
          component: <LibraryAddBatch entity="admin" /> 
        },
        { 
          label: 't(Remove from Library)', 
          svg: 'book_minus',
          action: 'unpublish', 
          config: { entity: 'admin' } 
        },
        { 
          label: 't(Update Folders)',
          svg: 'folder',
          action: 'folder', 
          component: <FoldersUpdateBatch entity="admin" />  
        },
        { 
          label: 't(Update Tags)',
          svg: 'tags',
          action: 'tag', 
          component: <TagsUpdateBatch entity="admin" />  
        }
      ]
    },
    recordTasks: (record) => [
      {
        label: 't(Add to Library)',
        svg: 'book_plus',
        show: record.status === 'draft',
        modal: <LibraryAdd scope="admin" entity="designs" record={ record } />
      },
      {
        label: 't(Remove from Library)',
        svg: 'book_minus',
        show: record.status === 'published',
        confirm: 't(Are you sure you want to unpublish this design?)',
        request: {
          endpoint: `/api/admin/truevail/admin/designs/${record.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Sucessfully unpublished design)'),
          onFailure: () => context.flash.set('error', 't(Unable to unpublish design)')
        }
      },
      {
        label: 't(Delete Design)',
        svg: 'trash',
        confirm: 't(Are you sure you want to delete this design?)',
        deletable: true,
        request: {
          endpoint: `/api/admin/truevail/admin/designs/${record.id}`,
          method: 'DELETE',
          onSuccess: () => context.flash.set('success', 't(Successfully deleted design)'),
          onFailure: () => context.flash.set('error', 't(Unable to delete design)')
        }
      }
    ],
    onClick: (record) => context.router.push(`/admin/truevail/admin/designs/${record.id}`)
  },
  task: {
    svg: 'plus',
    modal: New
  }
})

export default Panel(null, mapPropsToPanel)
