import CanvaEditor from '@admin/components/canva_editor'
import ProfileToken from '@admin/tokens/profile'
import ServiceToken from '@admin/tokens/service'
import Form from '@admin/components/form'
import CaptionField from './captionfield'
import PlacesField from './placesfield'
import PropTypes from 'prop-types'
import React from 'react'

class Content extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    config: PropTypes.object,
    editable: PropTypes.bool,
    title: PropTypes.string,
    version: PropTypes.object,
    versions: PropTypes.array,
    onChange: PropTypes.func,
    onSave: PropTypes.func
  }

  _handleCanvaEditor = this._handleCanvaEditor.bind(this)
  _handleSave = this._handleSave.bind(this)

  render() {
    const { versions, version } = this.props
    if(version && versions?.[0]?.record?.id !== version?.record?.id) return null
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { config, title, version } = this.props
    const { features } = this.context.admin
    return {
      showHeader: false,
      onChange: this._handleUpdate.bind(this, false),
      ...version ? {
        buttons: [
          { 
            label: 't(Save)', 
            color: 'blue', 
            disabled: _.isEqual(version.current.entity, config),
            handler: this._handleSave
          }
        ]  
      } : {},
      sections: [
        {
          fields: [
            ...!version && config.content.profile?.profile ? [
              {
                label: 't(Profile)',
                type: 'text',
                value: config.content.profile.profile,
                format: () => <ProfileToken profile={ config.content.profile.profile } />,
                bordered: true
              }  
            ] : !version && config.entity ? [
              {
                label: 't(Network)',
                type: 'text',
                value: config.entity,
                format: ServiceToken,
                bordered: true
              }
            ] : [],
            { 
              label: 't(Photos)', 
              name: 'content.photos', 
              type: 'mediafield', 
              multiple: true, 
              editor: version ? 'modal' : 'inline', 
              allow: { 
                types: ['photos'], unsplash: true 
              }, 
              min_ratio: 0.8, 
              max_ratio: 1.91, 
              show: config.content.type === 'photo', 
              value: config.content.photos ? config.content.photos.map(({photo}) => photo) : null, 
              required: true,
              ...title && features.canva === true ? {
                after: <CanvaEditor { ...this._getCanvaEditor() } />
              } : {}
            },
            { 
              label: 't(Video)', 
              name: 'content.video', 
              type: 'attachmentfield', 
              show: config.content.type === 'video', 
              formatter: (asset) => ({ asset }), requirements: [
                { type: 'type', value: ['mp4','mov'] },
                { type: 'min_duration', value: 3 },
                { type: 'max_duration', value: 60 }
              ], 
              allow: { types: ['videos'], unsplash: true }, 
              prompt: 'Choose Video', 
              multiple: false, 
              show_preview: false,
              defaultValue: config.content.video ? config.content.video.asset_id : null, 
              required: true 
            },
            { 
              label: 't(Reel)', 
              name: 'content.reel', 
              type: 'attachmentfield', 
              show: config.content.type === 'reel', 
              formatter: (asset) => ({ asset }), 
              requirements: [
                { type: 'type', value: ['mp4','mov'] },
                { type: 'ratio', value: 9/16, text: '9:16' },
                { type: 'min_resolution', value: '540x960' },
                { type: 'max_resolution', value: '1080x1920' },
                { type: 'min_duration', value: 3 },
                { type: 'max_duration', value: 60 }
              ], 
              allow: { types: ['videos'], unsplash: true }, 
              prompt: 'Choose Video', 
              multiple: false, 
              show_preview: false,
              defaultValue: config.content.reel ? config.content.reel.asset_id : null, 
              required: true 
            },
            { 
              label: 't(Story)', 
              type: 'segment', 
              fields: [
                { 
                  type: 'radiogroup', 
                  name: 'content.story_type', 
                  options: [
                    { value: 'photo', text: 't(Photo Story)' },
                    { value: 'video', text: 't(Video Story)' }
                  ], 
                  defaultValue: config.content.story_type || 'photo' 
                },
                {
                  label: 't(Photo)', 
                  name: 'content.story_photo', 
                  type: 'mediafield', 
                  show: config.content.type === 'story' && config.content.story_type === 'photo', 
                  ratio: 9/16, editor: version ? 'modal' : 'inline', formatter: (asset) => {
                    return { asset }
                  },
                  allow: { types: ['photos'], unsplash: true }, 
                  prompt: 'Choose Photo(s)', 
                  defaultValue: config.content.story_photo ? config.content.story_photo : null, 
                  required: true 
                },
                { 
                  label: 't(Video)', 
                  name: 'content.story_video', 
                  type: 'attachmentfield', 
                  show: config.content.type === 'story' && config.content.story_type === 'video', 
                  formatter: (asset) => ({ asset }), requirements: [
                    { type: 'type', value: ['mp4','mov'] },
                    { type: 'ratio', value: 9/16, text: '9:16' },
                    { type: 'min_resolution', value: '540x960' },
                    { type: 'max_resolution', value: '1080x1920' },
                    { type: 'min_duration', value: 3 },
                    { type: 'max_duration', value: 60 }
                  ], 
                  allow: { types: ['videos'], unsplash: true  }, 
                  prompt: 'Choose Video', 
                  multiple: false,
                  show_preview: false,
                  defaultValue: config.content.story_video ? config.content.story_video.asset_id : null, 
                  required: true 
                }
              ], 
              show: config.content.type === 'story', 
              required: true 
            },
            { 
              label: 't(Link)', 
              name: 'content.link', 
              type: 'linkfield', 
              show: config.content.type === 'link', 
              showPreview: false, 
              formatter: (link) => {
                return { link }
              }, 
              defaultValue: config.content.link ? config.content.link.link : null, 
              required: true 
            },
            { 
              label: 't(Post Caption)', 
              name: 'content.text', type: CaptionField, 
              maxRows: 15, 
              show: _.includes(['photo','link','video','reel'], config.content.type), 
              placeholder: 'Enter a caption for this post', 
              defaultValue: config.content.text, 
              required: true,
              maxLength: 2200
            },
            ...features.social_location === true ? [
              { 
                label: 't(Location)', 
                type: 'segment', 
                fields: [
                  { 
                    name: 'content.add_location', 
                    type: 'checkbox', 
                    prompt: 't(Add a location)', 
                    defaultValue: config.content.add_location || false 
                  },
                  { 
                    name: 'content.location', 
                    type: PlacesField, 
                    show: config.content.add_location === true, 
                    defaultValue: config.content.location, 
                    required: true 
                  }
                ], 
                show: !_.includes(['story','video'], config.content.type) 
              }
            ] : [],
            { 
              label: 't(Comments)', 
              type: 'segment', 
              fields: [
                {
                  name: 'content.add_first_comment', 
                  type: 'checkbox', 
                  prompt: 't(Add first comment)', 
                  defaultValue: config.content.add_first_comment || false 
                },
                { 
                  name: 'content.first_comment', 
                  type: 'textarea', 
                  show: config.content.add_first_comment === true, 
                  rows: 1, 
                  placeholder: 't(Enter comment)', 
                  defaultValue: config.content.first_comment, 
                  required: true 
                }
              ], 
              show: config.content.type !== 'story' 
            }
          ]
        }
      ]
    }
  }

  _getCanvaEditor() {
    const { config, title } = this.props
    return {
      photos: config.content?.photos || [],
      title,
      label: 't(Edit Photos in Canva)',
      onDone: this._handleCanvaEditor
    }
  }

  _handleCanvaEditor(photos) {
    this._handleUpdate(true, {
      content: {
        ...this.props.config.content,
        photos
      }
    })
  }
  
  _handleSave() {
    this.props.onSave()
  }

  _handleUpdate(save, config) {
    this.props.onChange({
      ...this.props.config,
      ...config,
      content: {
        ...this.props.config.content,
        ...config.content,
        ...config.content.photos ? {
          photos: config.content.photos.map(photo => ({
            photo
          }))
        } : {}
      }
    }, save)
  }

}

export default Content
