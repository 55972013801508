import Button from '@admin/components/button'
import { getCode } from '@core/utils/codes'
import PropTypes from 'prop-types'
import Pasteur from 'pasteur'
import React from 'react'

class Body extends React.Component {

  static contextTypes = {
    provider: PropTypes.object
  }

  static propTypes = {
    actions: PropTypes.array,
    email: PropTypes.object,
    expandable: PropTypes.bool
  }

  iframeRef = React.createRef()
  pasteur = null
  isResizing = false
  resizeTimer = null

  state = {
    height: null
  }

  _handleResize = this._handleResize.bind(this)

  render() {
    const actions = this._getActions()
    return (
      <div className="maha-email-body">
        <iframe { ...this._getIframe() } />
        { actions &&
          <div className="maha-email-body-actions">
            { actions.map((action, index) => (
              <Button { ...this._getAction(action) } key={`action_${index}`} />
            )) }
          </div>
        }
      </div>
    )
  }

  componentDidMount() {
    const code = getCode(5)
    const content = this._getContent(code)
    this.iframeRef.current.contentWindow.document.write(content)
    this.pasteur = new Pasteur({
      window,
      target: this.iframeRef.current.contentWindow,
      name: `viewer-${code}`,
      targetName: `email-${code}`
    })
    
    // Debounced resize handler
    const debouncedResize = _.debounce((height) => {
      if (this.isResizing) return; // Skip if already processing
      this._handleResize(height);
    }, 200);
    
    this.pasteur.on('resize', debouncedResize)
  }

  _getActions() {
    const { actions } = this.props
    return actions ? actions.filter(action => {
      return action.label !== 'More'
    }) : null
  }

  _getIframe() {
    const { height } = this.state
    return {
      ref: this.iframeRef,
      style: {
        border: 0,
        height
      }
    }
  }

  _getAction(action) {
    return {
      ...action,
      fluid: false,
      basic: true,
      color: 'blue'
    }
  }

  _getContent(code) {
    const { email, expandable } = this.props
    const { provider } = this.context
    let html = email.html
    if(!email.html) {
      const text = email.text.replace(/(https?:\/\/[^\s]+)/g, (url) => `<a href="${url}">${url}</a>`)
      html = `<p>${ text.split('\n').join('<br />') }</p>`
    }
    if(!/<html/.test(html)) html = `<html>${html}</html>`
    if(!/<head/.test(html)) html = html.replace('<html([^>]*)>', '<html$1><head></head>')
    if(!/<head/.test(html)) html = html.replace('<html>', '<html><head></head>')
    if(!/<body/.test(html)) html = html.replace('</head>','</head><body>').replace('</html>','</body></html>')
    html = html.replace('</head>', `<link rel="stylesheet" type="text/css" href="${provider.cdn_host}/css/email.min.css"></head>`)
    html = html.replace(/<body([^>]*)>/, '<body$1><div id="maha-email-viewer">')
    html = html.replace('<body>', '<body><div id="maha-email-viewer">')
    html = html.replace('</body>', '</div></body>')
    html = html.replace('</body>', `<script src="${provider.cdn_host}/js/email.min.js"></script></body>`)
    html = html.replace('</body>', `<script nonce='${ window.nonce }'>new Email({ expandable: ${ expandable }, code: '${ code }' })</script></body>`)
    html = html.replace(/<a /g, '<a target="_blank" ')
    return html
  }

  _handleResize(height) {
    if (this.isResizing) return
    this.isResizing = true
    this.setState({ height }, () => {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        this.isResizing = false
      }, 500)
    })
  }

}

export default Body