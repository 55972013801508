import Actions from '@apps/automation/admin/components/actions'
import Container from '@admin/components/container'
import Comments from '@admin/components/comments'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class Demo extends React.PureComponent {

  static propTypes = {
    actions: PropTypes.object,
    activity: PropTypes.object,
    demo: PropTypes.object,
    enrollment: PropTypes.object,
    workflow: PropTypes.object
  }

  render() {
    const { demo, enrollment } = this.props
    return (
      <div className="crm-timeline-card-section">
        <div className="crm-timeline-card-section-body">
          <table className="ui celled compact unstackable table">
            <tbody>
              <tr>
                <td>Demo</td>
                <td><Button { ...this._getDemo() } /></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="crm-timeline-card-section-footer">
          { enrollment &&
            <Actions { ...this._getActions() } />
          }
          <Comments entity={`truevail_demos/${demo.id}`} />
        </div>
      </div>
    )
  }

  _getActions() {
    const { actions, enrollment, workflow } = this.props
    return {
      enrollment,
      actions,
      trigger: workflow.trigger
    }
  }

  _getEnrollment() {
    const { enrollment, workflow } = this.props
    return {
      label: 'View Enrollment',
      className: 'link',
      route: `/admin/automation/workflows/${workflow.id}/enrollments/${enrollment.id}`
    }
  }

  _getDemo() {
    const { demo} = this.props
    return {
      label: 'View Demo',
      className: 'link',
      route: `/admin/truevail/admin/demos/${demo.id}`
    }
  }

}

const mapResources = (props, context) => ({
  demo: `/api/admin/truevail/admin/demos/${props.activity.data.demo_id}`,
  ...props.activity.data.enrollment_id ? {
    actions: `/api/admin/automation/workflows/${props.activity.data.workflow_id}/enrollments/${props.activity.data.enrollment_id}/actions`,
    enrollment: `/api/admin/automation/workflows/${props.activity.data.workflow_id}/enrollments/${props.activity.data.enrollment_id}`,
    workflow: `/api/admin/automation/workflows/${props.activity.data.workflow_id}`  
  } : {}


})

export default Container(mapResources)(Demo)
