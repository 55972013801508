import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class LinkField extends React.Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    value: PropTypes.object,
    website: PropTypes.object,
    onChange: PropTypes.func,
    onReady: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {},
    onReady: () => {}
  }

  state = {
    link: null
  }

  _handleChange = _.debounce(this._handleChange.bind(this), 100, { trailing: true })
  _handleSanitize = this._handleSanitize.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)

  render() {
    if(!this.state.link) return null
    return (
      <div className="mjson-designer-stylefield">
        <Form { ...this._getForm() } />
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    this._handleSet(defaultValue)
    this.props.onReady()
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props
    const { link } = this.state
    if(!_.isEqual(link, prevState.link)) {
      this._handleChange()
    }
    if(!_.isEqual(value, prevProps.value)) {
      this._handleSet(value)
    }
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : {}
  }

  _getForm() {
    const { website } = this.props
    const { link } = this.state
    const options = this._getLinkStrategies()
    return {
      inline: true,
      onChange: this._handleUpdate,
      sections: [
        {
          fields: [
            { name: 'strategy', type: 'dropdown', options, direction: 'downward', defaultValue: link.strategy },
            { entity: 't(Anchor)', name: 'anchor', type: 'textfield', show: link.strategy === 'anchor', sanitize: this._handleSanitize, defaultValue: link.anchor },
            { entity: 't(URL)', name: 'url', type: 'urlfield', show: link.strategy === 'web', defaultValue: link.url },
            { entity: 't(Email)', name: 'email', type: 'emailfield', show: link.strategy === 'email', defaultValue: link.email },
            { entity: 't(Phone Number)', name: 'phone', type: 'phonefield', show: link.strategy === 'phone', defaultValue: link.phone },
            ...website ? [
              { entity: 't(Page)', name: 'page_id', type: 'lookup', endpoint: `/api/admin/websites/websites/${website.id}/pages`, valueKey: 'id', textKey: 'title', show: link.strategy === 'page', defaultValue: link.page_id }
            ] : [],
            { entity: 't(Form)', name: 'form_id', type: 'lookup', endpoint: '/api/admin/forms/forms', valueKey: 'id', textKey: 'title', show: link.strategy === 'form', defaultValue: link.form_id },
            { entity: 't(Landing Page)', name: 'landing_page_id', type: 'lookup', endpoint: '/api/admin/forms/landing_pages', valueKey: 'id', textKey: 'description', show: link.strategy === 'landing_page', defaultValue: link.landing_page_id },
            { entity: 't(Store)', name: 'store_id', type: 'lookup', search: false, endpoint: '/api/admin/stores/stores', valueKey: 'id', textKey: 'title', show: link.strategy === 'store', defaultValue: link.store_id },
            { entity: 't(File)', name: 'asset_id', type: 'attachmentfield', prompt: 't(Choose File)', show: link.strategy === 'asset', show_preview: false, defaultValue: link.asset_id },
            { entity: 't(Destination)', name: 'destination', type: 'textfield', show: link.strategy === 'other', defaultValue: link.destination }
          ]
        }
      ]
    }
  }

  _getLinkStrategies() {
    const { website } = this.props
    const strategies = [
      { value: 'none', text: 't(Nothing)' },
      { value: 'anchor', text: 't(Scroll to Anchor)' },
      { value: 'web', text: 't(Web Address)' },
      { value: 'email', text: 't(Email Address)' },
      { value: 'phone', text: 't(Phone Number)' },
      { value: 'asset', text: 't(File)' },
      ...website ? [
        { value: 'page', text: 't(Page)' }
      ] : [],
      { value: 'form', text: 't(Form)' },
      { value: 'landing_page', text: 't(Landing Page)' },
      { value: 'store', text: 't(Store)' },
      { value: 'other', text: 't(Other)' }
    ]
    return strategies
  }

  _handleChange() {
    const { link } = this.state
    this.props.onChange(link.strategy !== 'none' ? link : null)
  }

  _handleSet(link) {
    this.setState({
      link: {
        strategy: 'none',
        ...link || {}
      }
    })
  }

  _handleSanitize(value) {
    return value.toLowerCase().replace(/[^a-z0-9\-_]/g, '')
  }

  _handleUpdate(link) {
    this.setState({ link })
  }

}

export default LinkField
