import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    aesthetic_profile: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { aesthetic_profile } = this.props
    return {
      ref: this.formRef,
      title: 't(Edit Aesthetic Profile)',
      method: 'PATCH',
      endpoint: `/api/admin/truevail/admin/aesthetic_profiles/${aesthetic_profile.id}/edit`,
      action: `/api/admin/truevail/admin/aesthetic_profiles/${aesthetic_profile.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Description)', name: 'description', type: 'textarea' },
            { 
              label: 't(Agency)', 
              name: 'agency_id', 
              type: 'dropdown',
              endpoint: '/api/admin/truevail/admin/agencies', 
              valueKey: 'id', 
              textKey: 'team.title'
            },
            { label: 't(Thumbnail)', name: 'thumbnail_id', type: 'attachmentfield', multiple: false, allow: { content_types: ['images'] } },
            { label: 't(Poster)', name: 'poster_id', type: 'attachmentfield', multiple: false, allow: { content_types: ['images'] } },
            { label: 't(Video)', name: 'video_id', type: 'attachmentfield', multiple: false, allow: { content_types: ['videos'] } },
            { label: 't(Design URL)', name: 'design_url', type: 'urlfield' },
            { label: 't(Single Page Website)', name: 'single_page_url', type: 'urlfield' },
            { label: 't(Seven Page Website)', name: 'seven_page_url', type: 'urlfield' },
            { label: 't(Email Campaign)', name: 'email_campaign_url', type: 'urlfield' },
            { label: 't(Landing Page)', name: 'landing_page_url', type: 'urlfield' }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Edit
