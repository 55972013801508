import TemplateToken from '@apps/truevail/admin/tokens/template'
import EntityChooser from '@admin/components/entity_chooser'
import PropTypes from 'prop-types'
import React from 'react'

class Templates extends React.PureComponent {

  static contextTypes = {
    flash: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object,
    endpoint: PropTypes.string,
    type: PropTypes.string
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <EntityChooser { ...this._getEntityChooser() } />
  }

  _getEntityChooser() {
    const { advisor, type } = this.props
    return {
      entity: 'template',
      multiple: false,
      screenshot: (entity) => `truevail_template_variants/${entity.variants[0].code}`,
      search: {
        compareKey: 'id',
        endpoint: `/api/admin/truevail/library/advisors/${advisor.id}/templates/${type}`,
        format: (template) => (
          <TemplateToken template={ template } show_provider={ true } />
        ),
        textKey: 'title',
        valueKey: null
      },
      onCancel: this._handleCancel,
      onDone: this._handleSubmit
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSubmit(truevail_template) {
    const { endpoint } = this.props
    this.context.network.request({
      title: 't(Apply Template)',
      endpoint,
      method: 'PATCH',
      body: {
        truevail_template_id: truevail_template.id
      },
      onFailure: () => this.centext.flash.set('error', 't(Unable to apply template)'),
      onSuccess: this._handleSuccess
    })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default Templates
