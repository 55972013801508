import TagsUpdateBatch from '@apps/crm/admin/components/tags_update_batch'
import ScheduleBatch from '@apps/truevail/admin/components/schedule_batch'
import PreviewBatch from '@apps/truevail/admin/components/preview_batch'
import PublishingToken from '@apps/truevail/admin/tokens/publishing'
import Preview from '@apps/truevail/admin/components/preview'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import Schedule from '../schedule'
import Publish from '../publish'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Social Campaigns)',
  collection: {
    endpoint: `/api/admin/truevail/agency/social_campaigns/${props.social_campaign.id}/publishings`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Advisor)', key: 'advisor.title', sort: 'advisor', format: (publishing) => (
        <PublishingToken publishing={ publishing } />
      ) },
      { label: 't(Published)', key: 'created_at', collapsing: true, format: 'datetime' },
      { label: 't(Preview)', key: 'preview_status', collapsing: true, align: 'center', format: (publishing) => (
        <StatusToken status={ publishing.preview_status } />
      ) }
    ],
    defaultSort: { key: 'advisor', order: 'asc' },
    empty: {
      svg: 'messages_square',
      title: 't(No Publishings)',
      text: 't(There are no publishings for this social campaign)',
      buttons: [
        {
          label: 't(Publish Social Campaigns)',
          modal: <Publish social_campaign={ props.social_campaign } />
        }
      ]
    },
    entity: 'publishing',
    selectable: true,
    batch: {
      batch_type: 'truevail_publishings',
      tasks: [
        { 
          label: 't(Update Primary Contact Tags)', 
          svg: 'tag',
          config: { social_campaign_id: props.social_campaign.id },
          action: 'tag',
          component: TagsUpdateBatch
        },
        { 
          label: 't((Re)schedule All)', 
          svg: 'clock',
          action: 'schedule', 
          config: { social_campaign_id: props.social_campaign.id },
          component: <ScheduleBatch type="email" />
        },
        { 
          label: 't(Unschedule All)', 
          svg: 'clock',
          action: 'unschedule', 
          config: { social_campaign_id: props.social_campaign.id },
          confirm: 't(Are you sure you want to unpublish these publishings?)'
        },
        { 
          label: 't(Send Previews)', 
          svg: 'mail',
          action: 'preview', 
          config: { social_campaign_id: props.social_campaign.id },
          component: <PreviewBatch type="email" />
        },
        { 
          label: 't(Republish All)', 
          svg: 'refresh_cw',
          action: 'republish', 
          config: { social_campaign_id: props.social_campaign.id }
        },
        { 
          label: 't(Unpublish All)', 
          svg: 'ban',
          action: 'unpublish', 
          config: { social_campaign_id: props.social_campaign.id },
          confirm: 't(Are you sure you want to unpublish these publishings?)'
        },
        { 
          label: 't(Delete All)', 
          svg: 'trash',
          action: 'delete', 
          deletable: true, 
          config: { social_campaign_id: props.social_campaign.id },
          confirm: 't(Are you sure you want to delete these publishings?)'
        }
      ]
    },
    recordTasks: (record) => [
      {
        label: 't(Send Preview)',
        svg: 'mail',
        modal: <Preview publishing={ record } scope="agency" type="social" />
      },
      {
        label: 't(Republish Social Campaign)',
        svg: 'refresh_cw',
        show: _.includes(['draft','scheduled','unpublished'], record.advisor_social_campaign.status),
        request: {
          endpoint: `/api/admin/truevail/agency/publishings/${record.id}/republish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully republished social campaign)'),
          onFailure: () => context.flash.set('error', 't(Unable to republish social campaign)')
        }
      },
      {
        label: 't(Schedule Social Campaign)',
        svg: 'clock',
        show: _.includes(['draft','unpublished'], record.advisor_social_campaign.status),
        modal: <Schedule social_campaign_id={ props.social_campaign.id } publishing={ record } />
      },
      {
        label: 't(Reschedule Social Campaign)',
        svg: 'refresh_cw',
        show: record.advisor_social_campaign.status === 'scheduled',
        modal: <Schedule social_campaign_id={ props.social_campaign.id } publishing={ record } />
      },
      {
        label: 't(Unpublish Social Campaign)',
        svg: 'ban',
        show: _.includes(['draft','scheduled'], record.advisor_social_campaign.status),
        request: {
          endpoint: `/api/admin/truevail/agency/publishings/${record.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully unpublished social campaign)'),
          onFailure: () => context.flash.set('error', 't(Unable to unpublish social campaign)')
        }
      },
      {
        label: 't(Unschedule Social Campaign)',
        svg: 'clock',
        show: record.advisor_social_campaign.status === 'scheduled',
        request: {
          endpoint: `/api/admin/truevail/agency/publishings/${record.id}/unschedule`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully unscheduled social campaign)'),
          onFailure: () => context.flash.set('error', 't(Unable to unschedule social campaign)')
        }
      },
      // {
      //   label: 't(Delete Social Campaigns)',
      //   svg: 'trash',
      //   confirm: 't(Are you sure you want to delete these social campaigns?)',
      //   deletable: true,
      //   request: {
      //     endpoint: `/api/admin/truevail/agency/social_campaigns/${props.social_campaign.id}/publishings/${record.id}`,
      //     method: 'DELETE',
      //     onSuccess: () => context.flash.set('success', 't(Successfully deleted social campaigns)'),
      //     onFailure: () => context.flash.set('error', 't(Unable to delete social campaigns)')
      //   }
      // }
    ],
    onClick: (record) => context.host.window.open(`/${record.subscription.advisor.team.subdomain}/campaigns/social/${record.advisor_social_campaign.id}`)
  },
  task: {
    svg: 'plus',
    tooltip: 't(Publish Social Campaigns)',
    modal: <Publish social_campaign={ props.social_campaign } />
  }
})

export default Panel(null, mapPropsToPanel)
