import FileBrowser from '@admin/components/file_browser'
import ModalPanel from '@admin/components/modal_panel'
import PropTypes from 'prop-types'
import React from 'react'

class Browser extends React.Component {

  static propTypes = {
    allow: PropTypes.object,
    chooseCollections: PropTypes.bool,
    context: PropTypes.object,
    doneText: PropTypes.any,
    endpoint: PropTypes.string,
    files: PropTypes.array,
    folders: PropTypes.array,
    multiple: PropTypes.bool,
    prompt: PropTypes.string,
    q: PropTypes.string,
    root: PropTypes.object,
    showHeader: PropTypes.bool,
    source: PropTypes.object,
    view: PropTypes.string,
    onAdd: PropTypes.func,
    onBack: PropTypes.func,
    onChangeFolder: PropTypes.func,
    onNext: PropTypes.func,
    onRemove: PropTypes.func,
    onPop: PropTypes.func,
    onPush: PropTypes.func,
    onSetQuery: PropTypes.func,
    onSetView: PropTypes.func,
    onToggleView: PropTypes.func,
    onUp: PropTypes.func
  }

  static defaultProps = {
    showHeader: true
  }
  
  _handleBack = this._handleBack.bind(this)
  _handleChooseFile = this._handleChooseFile.bind(this)
  _handleNext = this._handleNext.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <FileBrowser { ...this._getFileBrowser() } />
      </ModalPanel>
    )
  }

  _getFileBrowser() {
    const { source } = this.props
    return {
      ...this.props,
      root:  {
        id: null,
        image: `/images/services/${source.service.source_name}.png`,
        name: source.username  
      },
      onChooseFile: this._handleChooseFile
    }
  }

  _getPanel() {
    const { doneText, files, showHeader } = this.props
    return {
      title: 't(Choose File(s))',
      showHeader,
      leftItems: [
        { svg: 'chevron_left', handler: this._handleBack }
      ],
      rightItems: files.length > 0 ? [
        { label: doneText, handler: this._handleNext }
      ] : []
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChooseFile(file) {
    const { source, endpoint, files } = this.props
    const index = _.findIndex(files, { id: file.id, service: source.service })
    if(index >= 0) return this.props.onRemove(index)
    this.props.onAdd({
      id: file.id,
      create: {
        endpoint,
        body: { id: file.id }
      },
      source_id: source.id,
      name: file.name,
      service: source.service,
      content_type: file.content_type,
      thumbnail: file.thumbnail,
      status: 'pending'
    })
  }

  _handleNext() {
    this.props.onNext()
  }

}

export default Browser
