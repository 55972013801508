import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    folder: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { folder } = this.props
    return {
      title: 't(Edit Folder)',
      method: 'PATCH',
      endpoint: `/api/admin/truevail/admin/folders/${folder.id}/edit`,
      action: `/api/admin/truevail/admin/folders/${folder.id}`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Title)', 
              name: 'title', 
              type: 'textfield', 
              required: false 
            },
            { 
              label: 't(Thumbnail)', 
              name: 'thumbnail_id', 
              type: 'attachmentfield', 
              multiple: false 
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(post) {
    this.context.modal.close()
  }

}

export default Edit
