import Participant from './participant'
import Demo from './demo'

const cards = {
  demo_completed: { component: Demo },
  demo_noshowed: { component: Demo },
  demo_scheduled: { component: Demo },
  participant_attended: { component: Participant },
  participant_noshowed: { component: Participant },
  participant_registered: { component: Participant }
}

export default cards
