import { parsePhoneNumberFromString } from 'libphonenumber-js'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import React from 'react'

class PhoneNumber extends React.Component {

  static propTypes = {
    number: PropTypes.string,
    type: PropTypes.string,
    link: PropTypes.bool
  }

  static defaultProps = {
    link: false
  }

  render() {
    const { number, type, link } = this.props
    const parsed = parsePhoneNumberFromString(number)
    const Component = link ? Button : 'span'
    return (
      <Component { ...this._getProps() }>
        { parsed ? 
          <span className="maha-phone-number-flag">
            { this._getFlag(parsed.country) } +{ parsed.countryCallingCode }
          </span> : '' 
        }
        <span className="maha-phone-number-label">
          { parsed?.formatInternational()?.replace(`+${parsed.countryCallingCode}`, '').replace(/(int|ext.) /, 'x') || number }
        </span>
        { type && 
          <span className="maha-phone-number-type">{type}</span>
        }
      </Component>
    )
  }

  _getProps() {
    const { number, link } = this.props
    return {
      className: 'maha-phone-number',
      ...link ? {
        url: `tel:${number.replace('ext=','')}`
      } : {}
    }
  }

  _getFlag(countryCode) {
    if (!countryCode) return ''
    const offset = 127397
    const codePoints = [...countryCode.toUpperCase()].map(char =>{
      return char.charCodeAt(0) + offset
    })
    return String.fromCodePoint(...codePoints)
  }

}

export default PhoneNumber
