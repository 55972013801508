import Page from '@admin/components/page'
import New from './new'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Deals)',
  collection: {
    endpoint: '/api/admin/sales/deals',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'contact.display_name', primary: true, sort: 'last_name' },
      { label: 't(Pipeline)', key: 'pipeline.title', sort: 'pipeline' },
      { label: 't(Stage)', key: 'stage.title', sort: 'stage' },
      { label: 't(Created)', key: 'created_at', format: 'datetime' },
      { label: 't(Last Transition)', key: 'last_transition_at', format: 'datetime' },
      { label: 't(Amount)', key: 'amount', collapsing: true, format: 'currency' }
    ],
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 'Pipeline', key: 'pipeline_id', type: 'select', endpoint: '/api/admin/sales/pipelines', valueKey: 'id', textKey: 'title' }
          ]
        }
      ]
    },
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      svg: 'heart_handshake',
      title: 't(No Deals)',
      text: 't(You have not yet created any deals)',
      buttons: [{
        label: 'Create Deal',
        modal: New
      }]
    },
    entity: 'deal',
    onClick: (record) => context.router.push(`/sales/deals/${record.id}`)
  },
  task: {
    svg: 'plus',
    modal: New
  }
})

export default Page(null, mapPropsToPage)
