import { parsePhoneNumberFromString, getExampleNumber, isValidPhoneNumber } from 'libphonenumber-js'
import examples from 'libphonenumber-js/examples.mobile.json'
import Input from '@admin/components/html/input'
import Button from '@admin/components/button'
import Icon from '@admin/components/icon'
import countries from './countries'
import PropTypes from 'prop-types'
import Country from './country'
import React from 'react'

class PhoneField extends React.Component {

  static propTypes = {
    defaultCountries: PropTypes.array,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    required: PropTypes.bool,
    tabIndex: PropTypes.number,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    onFocus: PropTypes.func,
    onReady: PropTypes.func,
    onValid: PropTypes.func
  }

  static defaultProps = {
    defaultCountries: ['US'],
    onChange: () => {},
    onEnter: () => {},
    onReady: () => {}
  }

  state = {
    countryCode: 'US',
    focused: false,
    open: false,
    pasted: null,
    text: '',
    value: null
  }

  _handleBlured = this._handleBlured.bind(this)
  _handleClear = this._handleClear.bind(this)
  _handleCountry = this._handleCountry.bind(this)
  _handleFocused = this._handleFocused.bind(this)
  _handleKeyUp = this._handleKeyUp.bind(this)
  _handleOpen = this._handleOpen.bind(this)
  _handlePaste = this._handlePaste.bind(this)
  _handleToggle = this._handleToggle.bind(this)
  _handleType = this._handleType.bind(this)
  _handleUpdate = this._handleUpdate.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    const { countryCode, open, text } = this.state
    const defaults = this._getDefaults()
    const selected = this._getDetails(countryCode)
    return (
      <div className={ this._getClass() }>
        <div className="maha-input">
          <div className="maha-phonefield-flag">
            <div className="maha-phonefield-flag-inner" onClick={ this._handleToggle }>
              <i className={`emoji emoji-${ selected.flag }`} />
              <Icon icon="caret-down" />
            </div>
          </div>
          <div className="maha-input-field">
            <div className="maha-phonefield-prefix">
              { selected.prefix }
            </div>
            <div className="maha-phonefield-number">
              <Input { ...this._getInput(selected) } />
            </div>
          </div>
          { text && text.length > 0 &&
            <Button { ...this._getClear() } />
          }
        </div>
        { open &&
          <div className="maha-phonefield-countries">
            { defaults.map((country, index) => (
              <Country { ...this._getCountry(country) } key={`default_${index}`} />
            )) }
            <div className="maha-phonefield-countries-divider" />
            { countries.map((country, index) => (
              <Country { ...this._getCountry(country) } key={`all_${index}`} />
            )) }
          </div>
        }
      </div>
    )
  }

  componentDidMount() {
    const defaultValue = this._getDefaultValue()
    if(!_.isNil(defaultValue)) this._handleSet(defaultValue)
    this.props.onReady(this._handleValidate)
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.value !== prevState.value) {
      this._handleChange()
    }
    if(this.props.value !== prevProps.value) {
      this._handleSet(this.props.value)
    }
  }

  _getClass() {
    const { disabled } = this.props
    const { focused, open } = this.state
    const classes = ['maha-input','maha-phonefield']
    if(disabled) classes.push('disabled')
    if(focused) classes.push('focused')
    if(open) classes.push('opened')
    return classes.join(' ')
  }

  _getClear() {
    return {
      svg: 'x',
      className: 'maha-input-action',
      handler: this._handleClear
    }
  }

  _getCountry(country) {
    return {
      country,
      onChoose: this._handleCountry
    }
  }

  _getDefaults() {
    const { defaultCountries } = this.props
    return countries.filter(country => {
      return _.includes(defaultCountries, country.code)
    })
  }

  _getDefaultValue() {
    const { defaultValue, value } = this.props
    return !_.isNil(value) ? value : !_.isNil(defaultValue) ? defaultValue : null
  }

  _getDetails(countryCode) {
    const country = _.find(countries, { code: countryCode })
    const prefix = `+${country.dialCode}`
    let number = getExampleNumber(countryCode, examples)
    number = countryCode === 'US' ? number.formatNational() : number.formatInternational().replace(`${prefix} `, '')
    return {
      flag: country.emoji,
      prefix,
      template: `${number.replace(/\d/g, 'X')} ext. XXXXXX`
    }
  }

  _getFormatted(partial, countryCode) {
    const details = this._getDetails(countryCode)
    let index = -1
    const numbers = partial.replace(/[^\d]*/g, '').split('')
    return details.template.split('').reduce((formatted, char) => {
      if(index >= numbers.length - 1) return formatted
      if(char !== 'X') return formatted + char
      index = index + 1
      return formatted + numbers[index]
    }, '')
  }

  _getInput(selected) {
    const { focused, text } = this.state
    const { id, tabIndex } = this.props
    return {
      id,
      className: 'ui input',
      type: 'tel',
      placeholder: !focused ? selected.template : '',
      tabIndex,
      value: text,
      onBlur: this._handleBlured,
      onFocus: this._handleFocused,
      onKeyUp: this._handleKeyUp,
      onChange: this._handleType,
      onPaste: this._handlePaste
    }
  }

  _handleBlured() {
    const { onBlur } = this.props
    this.setState({
      focused: false
    })
    if(onBlur) onBlur()
  }

  _handleChange() {
    const { value } = this.state
    if(value && !isValidPhoneNumber(value)) return
    this.props.onChange(value)
  }

  _handleClear() {
    this.setState({
      text: '',
      value: null
    })
  }

  _handleCountry(countryCode) {
    const { text } = this.state
    this._handleUpdate(text, countryCode)
  }

  _handleFocused() {
    const { onFocus } = this.props
    this.setState({
      focused: true
    })
    if(onFocus) onFocus()
  }

  _handleKeyUp(e) {
    if(e.key !== 'Enter') return
    this.props.onEnter()
  }

  _handleOpen() {
    this.setState({
      open: true
    })
  }
  
  _handleSet(raw) {
    if(!raw || raw.length === 0) return this._handleClear()
    const parsedRaw = parsePhoneNumberFromString(raw)
    const countryCode = parsedRaw ? parsedRaw.country : this.state.countryCode
    const details = this._getDetails(countryCode)
    const value = raw.startsWith(details.prefix) ? raw.replace(details.prefix, '') : raw
    const parsed = parsePhoneNumberFromString(value, countryCode)
    this.setState({
      countryCode,
      text: parsed ? this._getFormatted(value, countryCode) : value,
      value: parsed ? parsed.getURI().replace('tel:', '') : value
    })
  }

  _handlePaste(e) {
    this.setState({
      pasted: e.clipboardData.getData('Text')
    })
  }

  _handleToggle() {
    const { open } = this.state
    this.setState({
      open: !open
    })
  }

  _handleType(value) {
    const { pasted } = this.state
    let countryCode = this.state.countryCode
    let raw = pasted || value
    const parsed = parsePhoneNumberFromString(raw)
    if(parsed) {
      raw = parsed.getURI().replace('tel:','').replace(';ext=','').replace(`+${parsed.countryCallingCode}`,'')
      countryCode = parsed.country
    }
    this._handleUpdate(raw, countryCode)
  }

  _handleUpdate(raw, countryCode) {
    const text = this._getFormatted(raw, countryCode)
    const parsed =  parsePhoneNumberFromString(text, countryCode)
    this.setState({
      countryCode,
      open: false,
      pasted: null,
      text,
      value: parsed ? parsed.getURI().replace('tel:', '') : raw
    })
  }

  _handleValidate() {
    const { required } = this.props
    const { countryCode, value } = this.state
    if(required && (!value || value.length === 0)) {
      return this.props.onValid(null, ['This field is required'])
    }
    if(!required && !value) return this.props.onValid(value)
    const parsed = parsePhoneNumberFromString(value, countryCode)
    if(!parsed || !parsed.isValid()) {
      return this.props.onValid(null, ['This number is not a valid format'])
    }
    this.props.onValid(value)
  }

}

export default PhoneField
