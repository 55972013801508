
import { useLocaleContext } from '@admin/components/locale'
import PropTypes from 'prop-types'
import svgs from './svgs'
import React from 'react'

const Icon = (props) => {

  const { locale } = useLocaleContext()
  const { animation, className, icon, svg, stroke_width, tooltip, ...additionalProps } = props
  const onClick = props.onClick || null

  const mergedProps = {
    ...additionalProps,
    ...icon ? {
      className: `fa fa-${icon}`
    } : {},
    ...animation ? { className: animation } : {},
    ...stroke_width ? {
      strokeWidth: stroke_width
    } : {}
  }

  const Svg = svgs?.[svg]

  const classes = ['maha-icon']
  if(className) classes.push(className)
  if(svg) classes.push(svg)

  const divProps = {
    className: classes.join(' '),
    ...tooltip ? {
      'data-tooltip': _.isString(tooltip) ? locale.t(tooltip) : locale.t(tooltip.title),
      'data-position': _.isString(tooltip) ? 'bottom right' : tooltip.position,
      'data-inverted': ''
    } : {},
    ...onClick ? { onClick } : {}
  }

  return (
    <div { ...divProps }>
      { icon ? <i { ...mergedProps } /> : Svg ? <Svg { ...mergedProps } /> : null }
    </div>
  )

}

Icon.propTypes = {
  animation: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
  stroke_width: PropTypes.number,
  svg: PropTypes.string,
  tooltip: PropTypes.any,
  onClick: PropTypes.func
}

export default Icon