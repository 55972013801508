import FolderToken from '@apps/truevail/admin/tokens/folder'
import Panel from '@admin/components/panel'
import React from 'react'
import Edit from './edit'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Folders)',
  collection: {
    endpoint: '/api/admin/truevail/agency/folders',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (folder) => (
        <FolderToken folder={ folder } />
      ) },
      { label: 't(Items)', key: 'items_count', collapsing: true }
    ],
    defaultSort: { key: 'title', order: 'asc' },
    empty: {
      icon: 'folder',
      title: 't(No Folders)',
      text: 't(You have not yet created any folders)',
      buttons: [
        {
          label: 't(Create Folder)',
          modal: New
        }
      ]
    },
    recordTasks: (record) => [
      {
        label: 't(Edit Folder)',
        svg: 'square_pen',
        modal: <Edit folder={ record } />
      },
      {
        label: 't(Delete Folder)',
        svg: 'trash',
        confirm: 't(Are you sure you want to delete this folder?)',
        deletable: true,
        request: {
          endpoint: `/api/admin/truevail/agency/folders/${record.id}`,
          method: 'DELETE',
          onSuccess: () => context.flash.set('success', 't(Successfully deleted folder)'),
          onFailure: () => context.flash.set('error', 't(Unable to delete folder)')
        }
      }
    ],
    entity: 'user'
  },
  task: {
    svg: 'plus',
    tooltip: 't(Create Folder)',
    modal: New
  }
})

export default Panel(null, mapPropsToPanel)
