import ContactCard from '@apps/crm/admin/components/contactcard'
import ProgramForm from '@apps/crm/admin/components/programform'
import ProgramToken from '@apps/crm/admin/tokens/program'
import PhoneNumber from '@admin/components/phone_number'
import Content from '@apps/forms/admin/tokens/content'
import TagToken from '@apps/crm/admin/tokens/tag'
import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Icon from '@admin/components/icon'
import React, { Fragment } from 'react'
import T from '@admin/components/t'
import PropTypes from 'prop-types'
import Lists from './lists/edit'
import Tags from './tags/edit'
import Merge from '../merge'
import Edit from '../edit'

class Overview extends React.Component {

  static contextTypes = {
    admin: PropTypes.object
  }

  static propTypes = {
    callable: PropTypes.bool,
    contact: PropTypes.object,
    emailable: PropTypes.bool,
    fields: PropTypes.array,
    programs: PropTypes.array,
    tags: PropTypes.array
  }

  render() {
    return <Details { ...this._getDetails() } />
  }

  _getDetails() {
    const { contact } = this.props
    const { admin } = this.context
    const programs = this._getPrograms()
    const tags = programs.filter(program => program.tags.length > 0)
    const properties = programs.filter(program => program.fields.length > 0)
    return {
      ...contact.deleted_at ? {
        alert: { color: 'red', message: 't(This contact was deleted)' }
      } : contact.provider_users.length > 0 ? {
        alert: { color: 'blue', message: (
          <>
            <strong><T text={ 't(This contact belongs to the teams)' } />:</strong> { 
              contact.provider_users.map((user, index) => user.team.title).join(', ') 
            }
          </>
        ) }
      } : {},
      before: <ContactCard contact={ contact } link={ false } />,
      sections: [
        {
          title: 't(Core Properties)',
          items: [
            { label: 't(Positions)', content: contact.positions.length > 0 ? contact.positions.map((position, index) => (
              <div key={`position_${index}`}>
                { [
                  position.organization,
                  ...position.job_title ? [position.job_title] : []
                ].join(', ') }
              </div>
            )) : null },
            { label: 't(Email Addresses)', content: contact.email_addresses.length > 0 ? contact.email_addresses.map((email_address, index) => (
              <div key={`email_address_${index}`}>
                { this._getEmailIcon(email_address) } <Button { ...this._getEmailAddress(email_address) } />
              </div>
            )) : null },
            { label: 't(Phone Numbers)', content: contact.phone_numbers.length > 0 ? contact.phone_numbers.map((phone_number, index) => (
              <div key={`number_${index}`}>
                { this._getPhoneIcon(phone_number) } <PhoneNumber number={ phone_number.number } type={ phone_number.type } link={ true } />
              </div>
            )) : null },
            { label: 't(Mailing Addresses)', content: contact.mailing_addresses.length > 0 ? contact.mailing_addresses.map((mailing_address, index) => (
              <div key={`mailing_address_${index}`}>
                <Button { ...this._getMailingAddress(mailing_address) } />
              </div>
            )) : null },
            { label: 't(Birthday)', content: contact.birthday, format: 'date' },
            { label: 't(Spouse)', content: contact.spouse },
            { label: 't(Prefered Language)', content: contact.language }
          ]
        },
        { 
          title: 't(Tags)',
          ...tags.length > 0 ? {
            items: tags.map((program, index) => ({
              label: !admin.team.has_programs ? 'Tags' : program.title,
              content: program.tags.map((tag, index) => (
                <TagToken value={ tag.text } key={`tag_${index}`} />
              ))
            }))
          } : {
            component: (
              <div className="token">
                <span className="disabled">NO TAGS</span>
              </div>
            ) 
          }
        },
        { title: 't(Custom Properties)' },
        ...properties.length > 0 ? properties.map(program => ({
          ...admin.team.has_programs ? {
            subtitle: <ProgramToken { ...program } />,
            padded: false
          } : {},
          items: program.fields.map(field => ({
            label: field.name.value,
            content: contact.values?.[field.code]?.length > 0 ? <Content data={ contact.values } field={ field } empty={ false } /> : null
          }))
        })) : [{
          component: (
            <div className="token">
              <span className="disabled">NO CUSTOM PROPERTIES</span>
            </div>
          ) 
        }]
      ]
    }
  }

  _getEmailAddress(email_address) {
    return {
      label: email_address.address,
      className: 'link',
      url: `mailto:${email_address.address}`
    }
  }

  _getEmailIcon(email_address) {
    if(email_address.was_hard_bounced) {
      return <Icon icon="exclamation-circle" title="An email delivered to this address was hard bounced" />
    }
    if(email_address.soft_bounce_count > 0) {
      return <Icon icon="exclamation-circle" title={`${email_address.soft_bounce_count} emails delivered to this address were soft bounced`} />
    }
    if(!email_address.is_valid) {
      return <Icon icon="exclamation-circle" title="This is an invalid email" />
    }
    return null
  }

  _getMailingAddress(mail_address) {
    return {
      label: mail_address.address.description,
      className: 'link',
      link: `https://www.google.com/maps/search/?api=1&query=${mail_address.address.description.replace(/,/g, '').replace(/\s/g,'+')}`
    }
  }

  _getOrganization(organization) {
    return {
      label: organization.name,
      className: 'link',
      route: `/crm/organizations/${organization.id}`
    }
  }

  _getPhoneIcon(phone_number) {
    if(phone_number.is_valid === false) {
      return <Icon icon="exclamation-circle" title="Could not verify number" />
    }
    if(phone_number.undelivered_count > 0) {
      return <Icon icon="exclamation-circle" title={`${phone_number.undelivered_count} sms messages sent to this number were unabled to be delivered`} />
    }
    return null
  }

  _getPrograms() {
    const { fields, tags } = this.props
    return this.props.programs.map(program => ({
      ...program,
      fields: fields.reduce((fields, p) => {
        return p.id === program.id ? p.fields: fields
      }, []),
      tags: tags.filter(tag => {
        return tag.program.id === program.id
      })
    })).sort((a,b) => {
      return a.title > b.title ? 1 : -1
    })
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview contact={ props.contact } callable={ props.callable } emailable={ props.emailable } duplicates={ props.duplicates } fields={ props.fields } programs={ props.programs } tags={ props.tags} />,
  tasks: {
    access: { rights: { $oneOf: ['crm:manage_contacts'] } },
    show: props.contact.deleted_at === null,
    items: [
      { 
        label: 't(Edit Contact)',
        svg: 'square_pen',
        modal: <Edit contact={ props.contact } /> 
      },
      { 
        label: 't(Manage Lists)', 
        svg: 'list',
        modal: <ProgramForm form={ Lists } props={{ contact: props.contact }} />
      },
      { 
        label: 't(Manage Tags)',
        svg: 'tags',
        modal: <ProgramForm form={ Tags } props={{ contact: props.contact }} /> 
      },
      { 
        label: 't(Merge Contact)',
        svg: 'merge',
        modal: <Merge programs={ props.programs } contact={ props.contact } fields={ props.fields } /> 
      },
      {
        label: 't(Delete Contact)',
        svg: 'trash',
        confirm: 't(Are you sure you want to delete this contact?)',
        deletable: true,
        request: {
          endpoint: `/api/admin/crm/contacts/${props.contact.id}`,
          method: 'DELETE',
          onSuccess: () => {
            context.flash.set('success', 't(Successfully deleted contact)')
            context.router.goBack()
          },
          onFailure: (result) => context.flash.set('error', 't(Unable to delete contact)')
        }
      }
    ]
  }
})

const mapResourcesToPanel = (props, context) => ({
  fields: '/api/admin/programs/fields',
  tags: `/api/admin/crm/contacts/${props.contact.id}/tags`
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
