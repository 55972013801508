import Searchbox from '@admin/components/searchbox'
import Infinite from '@admin/components/infinite'
import Message from '@admin/components/message'
import Button from '@admin/components/button'
import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Folder from './folder'
import List from './list'
import Grid from './grid'
import React from 'react'

class FileBrowser extends React.Component {

  static propTypes = {
    allow: PropTypes.object,
    chooseCollections: PropTypes.bool,
    context: PropTypes.object,
    doneText: PropTypes.any,
    endpoint: PropTypes.string,
    files: PropTypes.array,
    folders: PropTypes.array,
    multiple: PropTypes.bool,
    prompt: PropTypes.string,
    q: PropTypes.string,
    root: PropTypes.object,
    view: PropTypes.string,
    onChangeFolder: PropTypes.func,
    onChooseFile: PropTypes.func,
    onSetQuery: PropTypes.func,
    onSetView: PropTypes.func,
    onToggleView: PropTypes.func,
    onUp: PropTypes.func
  }

  static defaultProps = {
    allow: {},
    chooseCollections: false,
    prompt: 'Find a file...',
    showHeader: true
  }

  _handleClick = this._handleClick.bind(this)
  _handleToggleView = this._handleToggleView.bind(this)

  render() {
    const { q } = this.props
    return (
      <div className="maha-attachments-drive">
        <div className="maha-attachments-drive-head">
          <div className="maha-attachments-drive-head-bar">
            <div className="maha-attachments-drive-search">
              <Searchbox { ...this._getSearchbox() } />
            </div>
            <Button { ...this._getToogleView() } />
          </div>
        </div>
        <div className="maha-attachments-drive-body">
          { q.length === 0 && <Stack { ...this._getStack() } /> }
          { q.length > 0 && <Infinite { ...this._getInfinite() } /> }
        </div>
      </div>
    )
  }

  componentDidMount() {
    this._handleLoadView()
  }

  componentDidUpdate(prevProps) {
    const { view } = this.props
    if(view !== prevProps.view) {
      this._handleSaveView()
    }
  }

  _getFolder(folder) {
    const { allow, chooseCollections, endpoint, files, multiple, view, onUp } = this.props
    return {
      allow,
      chooseCollections,
      endpoint,
      files, 
      multiple,
      ...folder.type === 'collection' ? {
        collection: folder.item
      } : {
        folder: folder.item        
      },
      view,
      onClick: this._handleClick,
      onUp
    }
  }

  _getInfinite() {
    const { allow, chooseCollections, endpoint, files, multiple, q, view } = this.props
    const empty = {
      icon: 'times-circle',
      title: 't(No Results)',
      text: 't(There are no files that matched your query)'
    }
    const filter = q.length > 0 ? { q } : null
    return {
      endpoint,
      filter,
      empty,
      notFound: empty,
      layout: view === 'list' ? List : Grid,
      props: {
        allow,
        chooseCollections,
        endpoint,
        files, 
        multiple,
        onClick: this._handleClick
      }
    }
  }

  _getSearchbox() {
    const { prompt, onSetQuery } = this.props
    return {
      prompt,
      onChange: onSetQuery
    }
  }

  _getStack() {
    const { folders } = this.props
    const root = {
      type: 'folder',
      root: true,
      item: this.props.root
    }
    return {
      display_name: 'attachments_files',
      cards: [
        { component: Folder, props: this._getFolder.bind(this, root) },
        ...folders.map((folder) => ({ 
          component: Folder, 
          props: this._getFolder.bind(this, folder) 
        }))
      ]
    }
  }

  _getToogleView() {
    const { view } = this.props
    return {
      svg: view === 'list' ? 'table_properties' : 'layout_grid',
      className: 'maha-attachments-drive-view',
      handler: this._handleToggleView
    }
  }

  _handleClick(item) {
    const { chooseCollections, onChangeFolder, onChooseFile } = this.props
    // if(this._getDisabled(item)) return
    if(item.type === 'collection' && !chooseCollections) onChangeFolder({ type: 'collection', item })
    if(item.type === 'collection' && chooseCollections) onChooseFile(item)
    if(item.type === 'folder') onChangeFolder({ type: 'folder', item })
    if(item.type === 'file') onChooseFile(item)
  }

  _handleLoadView() {
    this.props.context.local_storage.get({
      key: 'file-explorer',
      onSuccess: (view) => {
        this.props.onSetView(view)
      },
      onFailure: () => {}
    })
  }

  _handleSaveView() {
    const { view } = this.props
    this.props.context.local_storage.set({
      key: 'file-explorer',
      value: view,
      onSuccess: () => {},
      onFailure: () => {}
    })
  }

  _handleToggleView() {
    this.props.onToggleView()
  }

}

export default FileBrowser
