import Infinite from '@admin/components/infinite'
import Img from '@admin/components/html/img'
import Icon from '@admin/components/icon'
import PropTypes from 'prop-types'
import React from 'react'
import Grid from './grid'
import List from './list'

class Folder extends React.Component {

  static propTypes = {
    allow: PropTypes.object,
    chooseCollections: PropTypes.bool,
    collection: PropTypes.object,
    endpoint: PropTypes.string,
    folder: PropTypes.object,
    files: PropTypes.array,
    multiple: PropTypes.bool,
    records: PropTypes.array,
    view: PropTypes.string,
    onClick: PropTypes.func,
    onUp: PropTypes.func
  }

  _handleUp = this._handleUp.bind(this)

  render() {
    const { collection, folder } = this.props
    return (
      <div className="maha-attachments-drive-folder">
        <div className="maha-attachments-drive-header">
          <div className="maha-attachments-drive-header-breadcrumb" onClick={ this._handleUp }>
            <div className="maha-attachments-drive-header-back">
              { folder.image ?
                <Img src={ folder.image } /> :
                <Icon svg="chevron_left" />
              }
            </div>
            <div className="maha-attachments-drive-header-label">
              { collection?.name || folder?.name }
            </div>
          </div>
        </div>
        <div className="maha-attachments-drive-results">
          <Infinite { ...this._getInfinite() } />
        </div>
      </div>
    )
  }

  _getInfinite() {
    const { allow, chooseCollections, collection, endpoint, files, folder, multiple, view, onClick } = this.props
    const empty = {
      svg: 'folder_open',
      title: 't(Empty Folder)',
      text: 't(There are no items in this folder)'
    }
    return {
      endpoint,
      defaultQuery: {
        ...collection ? { collection_id: collection.id } : {},
        ...folder ? { folder_id: folder.id } : {}
      },
      empty,
      notFound: empty,
      layout: view === 'list' ? List : Grid,
      props: {
        allow,
        chooseCollections,
        files,
        multiple,
        onClick
      }
    }
  }

  _handleUp() {
    const { collection, folder } = this.props
    if(!collection?.id && !folder?.id) return
    this.props.onUp()
  }

}

export default Folder
