import ProfileCollectionFailures from './postability/profile_collection_failures'
import PostCollectionFailures from './postability/post_collection_failures'
import DeliverabilityEmailCampaigns from './deliverability/email_campaigns'
import IncomingEmails from './deliverability/incoming_emails'
import DomainSegments from './deliverability/domain_segments'
import PostingFailures from './postability/posting_failures'
import DMARCFailures from './deliverability/dmarc_failures'
import Verifications from './deliverability/verifications'
import DMARCReports from './deliverability/dmarc_reports'
import AutomationWorkflows from './automation/workflows'
import CommunicationEmails from './communication/emails'
import Suspensions from './deliverability/suspensions'
import SocialPublishings from './social_publishings'
import AestheticProfiles from './aesthetic_profiles'
import Engagement from './deliverability/engagement'
import EmailPublishings from './email_publishings'
import AutomationEmails from './automation/emails'
import Forecast from './deliverability/forecast'
import SocialCampaigns from './social_campaigns'
import Holidays from './deliverability/holidays'
import Domains from './deliverability/domains'
import EmailCampaigns from './email_campaigns'
import SocialBatches from './social_batches'
import SenderDomains from './sender_domains'
import PricingModels from './pricing_models'
import SupportUsers from './support_users'
import Worst from './deliverability/worst'
import Page from '@admin/components/page'
import Template from './notices/template'
import Best from './deliverability/best'
import SignupPages from './signup_pages'
import Sent from './deliverability/sent'
import Okay from './deliverability/okay'
import Activities from './activities'
import Workflows from './workflows'
import Theme from './notices/theme'
import Suppliers from './suppliers'
import Templates from './templates'
import Webinars from './webinars'
import Channels from './channels'
import Profiles from './profiles'
import Agencies from './agencies'
import Advisors from './advisors'
import Senders from './senders'
import Notices from './notices'
import Signups from './signups'
import Folders from './folders'
import Designs from './designs'
import Forms from './forms'
import Users from './users'
import Demos from './demos'
import React from 'react'
import Tlds from './tlds'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Truevail)',
  manager: {
    path: '/admin/truevail/admin',
    items: [
      {
        label: 't(Agencies)',
        path: '/agencies',
        panel: <Agencies />
      },
      {
        label: 't(Advisors)',
        path: '/advisors',
        panel: <Advisors />
      },
      {
        label: 't(Suppliers)',
        path: '/suppliers',
        panel: <Suppliers />
      },
      {
        label: 't(Activities)',
        path: '/activities',
        panel: <Activities />
      },
      {
        label: 't(Users)',
        path: '/users',
        panel: <Users />
      },
      {
        label: 't(Automation)',
        access: { rights: { $allOf: ['automation:access_app'] } },
        children: [
          {
            label: 't(Workflows)',
            path: '/automation_workflows',
            panel: <AutomationWorkflows signup_page={ resources.signup_page } />
          },
          {
            label: 't(Emails)',
            path: '/automation_emails',
            panel: <AutomationEmails signup_page={ resources.signup_page } />
          }
        ]
      },
      {
        label: 't(Communication)',
        children: [
          {
            label: 't(Email)',
            path: '/emails',
            panel: <CommunicationEmails />
          }
        ]
      },
      {
        label: 't(Content Library)',
        children: [
          // {
          //   label: 't(Automation Emails)',
          //   path: '/automation_email',
          //   panel: <Templates type="automation_email" />
          // },
          {
            label: 't(Aesthetic Profiles)',
            path: '/aesthetic_profiles',
            panel: <AestheticProfiles />
          },
          {
            label: 't(Automation Emails)',
            path: '/automation_email_templates',
            panel: <Templates type="automation_email" />
          },
          {
            label: 't(Designs)',
            path: '/designs',
            panel: <Designs />
          },          
          {
            label: 't(Email Articles)',
            path: '/email_articles',
            panel: <Templates type="email_article" />
          },
          {
            label: 't(Email Campaigns)',
            path: '/email_campaigns',
            panel: <EmailCampaigns />
          },
          {
            label: 't(Email Introductions)',
            path: '/email_introductions',
            panel: <Templates type="email_introduction" />
          },
          {
            label: 't(Email Layouts)',
            path: '/email_layouts',
            panel: <Templates type="email_layout" />
          },
          {
            label: 't(Email Modules)',
            path: '/email_modules',
            panel: <Templates type="email_module" />
          },
          {
            label: 't(Email Templates)',
            path: '/email_templates',
            panel: <Templates type="email_template" />
          },
          {
            label: 't(Embeds)',
            path: '/embeds',
            panel: <Templates type="embed" />
          },
          {
            label: 't(Folders)',
            path: '/folders',
            panel: <Folders />
          },
          {
            label: 't(Forms)',
            path: '/forms',
            panel: <Forms />
          },
          {
            label: 't(Landing Pages)',
            path: '/landing_pages',
            panel: <Templates type="landing_page" />
          },
          {
            label: 't(Page Modules)',
            path: '/page_modules',
            panel: <Templates type="page_module" />
          },
          {
            label: 't(Social Batches)',
            path: '/social_batches',
            panel: <SocialBatches />
          },
          {
            label: 't(Social Campaigns)',
            path: '/social_campaigns',
            panel: <SocialCampaigns />
          },
          {
            label: 't(Workflows)',
            path: '/workflows',
            panel: <Workflows />
          }
        ]
      },
      {
        label: 't(Deliverability)',
        children: [
          {
            label: 't(Domains)',
            path: '/domains',
            panel: <Domains />
          },
          {
            label: 't(Domains Segments)',
            path: '/domain_segments',
            panel: <DomainSegments />
          },
          {
            label: 't(DMARC Failures)',
            path: '/dmarc_failures',
            panel: <DMARCFailures domain={ resources.domain } />
          },
          {
            label: 't(DMARC Reports)',
            path: '/dmarc_reports',
            panel: <DMARCReports domain={ resources.domain } />
          },
          {
            label: 't(Email Campaigns)',
            path: '/deliverability_email_campaigns',
            panel: <DeliverabilityEmailCampaigns />
          },
          {
            label: 't(Engagement)',
            path: '/engagement',
            panel: <Engagement />
          },
          {
            label: 't(Forecast)',
            path: '/deliverability_forecast',
            panel: <Forecast />
          },
          {
            label: 't(Holidays)',
            path: '/holidays',
            panel: <Holidays />
          },
          {
            label: 't(Incoming Emails)',
            path: '/incoming_emails',
            panel: <IncomingEmails />
          },
          {
            label: 't(Senders)',
            path: '/senders',
            panel: <Senders />
          },
          {
            label: 't(Sender Domains)',
            path: '/sender_domains',
            panel: <SenderDomains />
          },
          {
            label: 't(Senders - Best)',
            path: '/best',
            panel: <Best />
          },
          {
            label: 't(Senders - Okay)',
            path: '/okay',
            panel: <Okay />
          },
          {
            label: 't(Senders - Worst)',
            path: '/worst',
            panel: <Worst />
          },
          {
            label: 't(Sent)',
            path: '/sent',
            panel: <Sent />
          },
          {
            label: 't(Suspensions)',
            path: '/suspensions',
            panel: <Suspensions />
          },
          {
            label: 't(Verifications)',
            path: '/verifications',
            panel: <Verifications />
          }
        ]
      },
      {
        label: 't(Email)',
        children: [
          {
            label: 't(Channels)',
            path: '/email_channels',
            panel: <Channels service="email" />
          },
          {
            label: 't(Publishings)',
            path: '/email_publishings',
            panel: <EmailPublishings />
          }
        ]
      },
      {
        label: 't(Postability)',
        children: [
          {
            label: 't(Profiles)',
            path: '/profiles',
            panel: <Profiles />
          },
          {
            label: 't(Disconnected Profiles)',
            path: '/disconnected_profiles',
            panel: <Profiles status="disconnected" />
          },
          {
            label: 't(Expired Profiles)',
            path: '/expired_profiles',
            panel: <Profiles status="expired_password" />
          },
          {
            label: 't(Posting Failures)',
            path: '/posting_failures',
            panel: <PostingFailures />
          },
          {
            label: 't(Post Collection Failures)',
            path: '/post_collection_failures',
            panel: <PostCollectionFailures />
          },
          {
            label: 't(Profile Collection Failures)',
            path: '/profile_collection_failures',
            panel: <ProfileCollectionFailures />
          }
        ]
      },
      {
        label: 't(Social Media)',
        children: [
          {
            label: 't(Channels)',
            path: '/social_channels',
            panel: <Channels service="social" />
          },
          {
            label: 't(Publishings)',
            path: '/social_publishings',
            panel: <SocialPublishings />
          }
        ]
      },
      {
        label: 't(Truevail)',
        children: [
          {
            label: 't(Demos)',
            path: '/demos',
            panel: <Demos />
          },
          {
            label: 't(Notices)',
            path: '/notices',
            panel: <Notices />
          },
          {
            label: 't(Pricing Models)',
            path: '/pricing_models',
            panel: <PricingModels />
          },
          {
            label: 't(Signup Pages)',
            path: '/signup_pages',
            panel: <SignupPages />
          },
          {
            label: 't(Signups)',
            path: '/signups',
            panel: <Signups />
          },
          {
            label: 't(Template)',
            path: '/template',
            panel: <Template provider={ props.provider } />
          },
          {
            label: 't(Theme)',
            path: '/theme',
            panel: <Theme provider={ props.provider } />
          },
          {
            label: 't(TLDs)',
            path: '/tlds',
            panel: <Tlds />
          },
          {
            label: 't(Users)',
            path: '/support_users',
            panel: <SupportUsers />
          },
          {
            label: 't(Webinars)',
            path: '/webinars',
            panel: <Webinars />
          }
        ]
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  provider: '/api/admin/providers/2'
})

export default Page(mapResourcesToPage, mapPropsToPage)
