import { useLocalStorageContext } from '@admin/components/local_storage'
import useActionReducer from '@admin/hooks/use_action_reducer'
import FileBrowser from './file_browser'
import * as actions from './actions'
import PropTypes from 'prop-types'
import reducer from './reducer'
import React from 'react'

const FilesContainer = (props) => {

  const { local_storage } = useLocalStorageContext()

  const [state, handlers] = useActionReducer({
    display_name: 'file_browser',
    actions,
    props,
    reducer
  })

  const mergedProps = {
    context: {
      local_storage
    },
    ...props,
    ...state,
    ...handlers
  }

  return <FileBrowser { ...mergedProps } />

}

FilesContainer.propTypes = {}

export default FilesContainer