import EntityChooser from '@admin/components/entity_chooser'
import PropTypes from 'prop-types'
import Poster from './poster'
import React from 'react'

class Profile extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object,
    provider: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  constructor(props) {
    super(props)
    this.state = {
      config: props.formdata || {}
    }
  }

  render() {
    return <EntityChooser { ...this._getEntityChooser() } />
  }

  _getEntityChooser() {
    const { provider } = this.context
    const { advisor } = this.props.props
    const { config } = this.state
    return {
      entity: 'aesthetic profile',
      instructions: 't(Select from five luxury-inspired Aesthetic Profiles that capture your brand’s unique feel. The selected aesthetic profile will be applied to your newsletter template, landing page, opt-in forms, and optional website design. Each profile can be used as is or fully customized with your brand\'s colors and fonts on the next page.)',
      multiple: false,
      preview: (aesthetic_profile) => (
        <Poster aesthetic_profile={ aesthetic_profile } key={`aesthetic_profile_${aesthetic_profile.id}`} />
      ),
      search: {
        compareKey: 'id',
        defaultValue: config.aesthetic_profile_id,
        endpoint: `/api/admin/truevail/library/advisors/${advisor.id}/aesthetic_profiles`,
        format: (aesthetic_profile) => (
          <div className="campaign-token">
            <div className="campaign-token-preview">
              <div className="campaign-token-preview-inner">
                <img src={`${provider.cdn_host}/imagecache/w=100&fit=cover${aesthetic_profile.thumbnail.path}`} />
              </div>
            </div>
            <div className="campaign-token-label">
              <div className="token-link">
                { aesthetic_profile.title }
              </div>
              <div className="token-stats">
                { aesthetic_profile.tags.map(tag => tag.text).join(' • ') }
              </div>
            </div>
          </div>
        ),
        search: false    
      },
      showHeader: false,
      onDone: this._handleSuccess
    }
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(aesthetic_profile) {
    this.props.onNext({
      aesthetic_profile_id: aesthetic_profile.id
    })
  }

}

export default Profile
