import AssetIcon from '@admin/components/asset_icon'
import Image from '@admin/components/image'
import PropTypes from 'prop-types'
import print from 'print-js'
import React from 'react'
import bytes from 'bytes'

class AssetToken extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    content_type: PropTypes.string,
    download: PropTypes.bool,
    file_name: PropTypes.string,
    file_size: PropTypes.any,
    fingerprint: PropTypes.string,
    has_preview: PropTypes.bool,
    id: PropTypes.number,
    original_file_name: PropTypes.string,
    path: PropTypes.string,
    preview: PropTypes.string,
    route: PropTypes.string,
    shareable: PropTypes.bool,
    show_preview: PropTypes.bool,
    source: PropTypes.string,
    source_url: PropTypes.string,
    onClick: PropTypes.func
  }

  static defaultProps = {
    download: true,
    shareable: true,
    show_preview: true
  }

  linkRef = React.createRef()

  _handleClick = this._handleClick.bind(this)
  _handleDownload = this._handleDownload.bind(this)
  _handlePrint = this._handlePrint.bind(this)

  render() {
    const { content_type, original_file_name, file_name, file_size, has_preview, path, preview, shareable, show_preview, source, source_url } = this.props
    return (
      <div className={ this._getClass() } onClick={ this._handleClick }>
        <div className="maha-asset-token-details">
          <div className="maha-asset-token-icon">
            <AssetIcon content_type={ content_type } source={source} />
          </div>
          <div className="maha-asset-token-text">
            <div className="maha-asset-token-filename">
              <strong>{ original_file_name || file_name }</strong> ({ bytes(file_size, { decimalPlaces: 1 }) })
            </div>
            { shareable &&
              <div className="maha-asset-token-links">
                <div className="maha-asset-token-link" onClick={ this._handleDownload }>
                  Download
                </div>
                <span>|</span>
                <div className="maha-asset-token-link" onClick={ this._handlePrint }>
                  Print
                </div>
                { source_url &&
                  <>
                    <span>|</span>
                    <div className="maha-asset-token-link" onClick={ this._handleEdit.bind(this, source_url) }>
                      { source === 'google' && 'Open in Google Drive' }
                      { source === 'microsoft' && 'Open in OneDrive' }
                      <a href={ source_url } rel="noopener noreferrer" target="_blank" ref={ this.linkRef } />
                    </div>
                  </>
                }
              </div>
            }
          </div>
        </div>
        { show_preview &&
          <div className="maha-asset-token-preview">
            <div className="maha-asset-token-preview-frame">
              <Image src={  has_preview ? `/${preview}` : path } title={ original_file_name } transforms={{ w: 300 }} />
            </div>
          </div>
        }
      </div>
    )
  }

  _getClass() {
    const { file_name } = this.props
    const classes = ['maha-asset-token','maha-asset-token-click']
    if(file_name) classes.push(file_name.split('.').pop())
    return classes.join(' ')
  }

  _getPrint() {
    const { fingerprint, content_type } = this.props
    const { admin } = this.context
    if(content_type.match(/(jpeg|jpg|gif|png)/) !== null) {
      return {
        printable: admin.signUrl(`/api/admin/assets/${fingerprint}/view`),
        type: 'image'
      }
    } else if(content_type.match(/pdf/) !== null) {
      return {
        printable: admin.signUrl(`/api/admin/assets/${fingerprint}/view`),
        type: 'pdf'
      }
    } else {
      return {
        printable: admin.signUrl(`/api/admin/assets/${fingerprint}/print`),
        type: 'pdf'
      }
    }
  }

  _handleClick() {
    const { fingerprint, file_name, route, onClick } = this.props
    if(onClick) return onClick()
    if(route) return this.context.router.push(route)
    return this.context.router.push(`/admin/assets/${fingerprint}/${file_name}`)
  }

  _handleDownload(e) {
    e.stopPropagation()
    const { download, fingerprint } = this.props
    const { admin } = this.context
    if(!download) return
    window.location.href = admin.signUrl(`/api/admin/assets/${fingerprint}/download`)
  }

  _handleEdit(url, e) {
    this.linkRef.current.href = url
    this.linkRef.current.click()
    e.stopPropagation()
  }

  _handlePrint(e) {
    e.stopPropagation()
    const params = this._getPrint()
    print(params)
  }

}

export default AssetToken
