import Requirements from '@apps/truevail/admin/components/configuration/requirements'
import Initial from '@apps/truevail/admin/components/configuration/initial'
import Container from '@admin/components/container'
import PropTypes from 'prop-types'
import React from 'react'

class TruevailRoot extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    children: PropTypes.object,
    requirements: PropTypes.object
  }

  render() {
    return this.props.children
  }

  componentDidMount() {
    const { team, user } = this.context.admin
    const { requirements } = this.props.requirements
    if(user.type === 'support') return
    if(_.includes(['configured','pending','delayed','unconfigured'], team.status)) {
      return this._handleConfiguration()
    } else if(requirements && requirements.length > 0) {
      return this._handleRequirements()
    }
  }

  _handleConfiguration() {
    const { team } = this.context.admin
    this.context.modal.open(Initial, {
      dismissable: ['active','configured'].includes(team.status)
    })
  }

  _handleRequirements() {
    const { team } = this.context.admin
    const { requirements } = this.props.requirements
    this.context.modal.open(<Requirements requirements={ requirements } />, {
      dismissable: team.status === 'active'
    })
  }

}

const mapResources = (props, context) => ({
  requirements: '/api/admin/truevail/requirements'
})

export default Container(mapResources)(TruevailRoot)
