import DesignToken from '@apps/truevail/admin/tokens/design'
import EntityChooser from '@admin/components/entity_chooser'
import PropTypes from 'prop-types'
import React from 'react'

class DesignChooser extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object,
    network: PropTypes.object,
    provider: PropTypes.object
  }

  static propTypes = {
    design: PropTypes.object,
    onDone: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }  

  _handleDone = this._handleDone.bind(this)

  render() {
    return <EntityChooser { ...this._getEntityChooser() } />
  }

  _getEntityChooser() {
    const { profile } = this.props.design
    return {
      entity: 'design',
      multiple: false,
      screenshot: (entity) => `truevail_designs/${entity.code}`,
      file_browser: {
        root: {
          id: null,
          image: '/images/services/canva.png',
          name: 'Canva Designs'
        },
        source: profile
      },
      showHeader: false,
      onDone: this._handleDone
    }
  }

  _handleDone(design) {
    this.props.onDone({
      ...this.props.design,
      canva_thumbnail: design.thumbnails[0].asset,
      canva_title: design.title,
      remix_url: design.remix_url
    })
  }

}

export default DesignChooser
