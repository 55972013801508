import Message from '@admin/components/message'
import PropTypes from 'prop-types'
import React from 'react'

class Downloading extends React.Component {

  static contextTypes = {
    network: PropTypes.object
  }

  static propTypes = {
    design: PropTypes.object,
    profile: PropTypes.object,
    onNext: PropTypes.func
  }

  render() {
    return <Message { ...this._getMessage() } />
  }

  componentDidMount() {
    this._handleDownload()
  }

  _handleDownload() {
    const { design, profile } = this.props
    this.context.network.request({
      endpoint: `/api/admin/profiles/${profile.id}/canva/convert`,
      method: 'POST',
      body: {
        design_id: design.id
      },
      onSuccess: ({ data }) => {
        this.props.onNext({
          title: design.name.replace(/-/g, ' ').replace('Template', '-'),
          view_url: design.view_url.split('?')[0],
          thumbnails: data.map(asset => ({ 
            asset_id: asset.id,
            transforms: {
              crop: {
                l: 0,
                t: 0,
                w: asset.metadata.width,
                h: asset.metadata.height
              }
            }
          }))
        })
      }
    })
  }

  _getMessage() {
    return {
      svg: 'refresh_cw',
      spinning: true,
      title: 't(Downloading from Canva)',
      text: 't(We\'re downloading design assets from Canva)'
    }
  }

}

export default Downloading

