import FileBrowser from '@admin/components/file_browser'
import ModalPanel from '@admin/components/modal_panel'
import PropTypes from 'prop-types'
import React from 'react'

class Browser extends React.Component {

  static propTypes = {
    profile: PropTypes.object,
    onBack: PropTypes.func,
    onDone: PropTypes.func
  }

  _handleBack = this._handleBack.bind(this)
  _handleDone = this._handleDone.bind(this)
  
  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <FileBrowser { ...this._getFileBrowser() } />
      </ModalPanel>
    )
  }

  _getPanel() {
    return {
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Next)', color: 'blue', disabled: true }
      ]
    }
  }

  _getFileBrowser() {
    const { profile } = this.props
    return {
      chooseCollections: true,
      endpoint: `/api/admin/profiles/${profile.id}/files`,
      files: [],
      multiple: false,
      prompt: 't(Find a Canva design)...',
      root: {
        id: 'FAFkKBkYsSA',
        image: '/images/services/canva.png',
        name: 'Truevail-SM-Templates'
      },
      onChooseFile: this._handleDone
    }
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleDone(design) {
    const { profile } = this.props
    this.props.onDone({
      profile,
      design
    })
  }

}

export default Browser

